/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        common: {
            init: function() {
                /**
                 *  Add shrink class to header on scroll
                 */
                var $body = $("body"),
                    $threshold = 100,
                    $window = $(window);



                /**
                 *  Mobile Menu trigger
                 */
                $(".menu-trigger").on("click", function(e) {
                    e.preventDefault();
                    if ($body.hasClass("menu-open")) {
                        $body.removeClass("menu-open");
                    } else {
                        $body.addClass("menu-open");
                    }
                });

                /**
                 *  Scroll to anchor links
                 */
                $("a[href^='#']").on("click", function(e) {
                    e.preventDefault();
                    href = $(this).attr("href");

                    if (href.length > 1) {
                        $("html, body").animate(
                            {
                                scrollTop: $(href).offset().top - 65
                            },
                            500
                        );
                    }
                });

                /**
                 *  Banner arrow functionality (on interior banner and home banner)
                 */

                 $('.banner-arrow').on("click", function(e) {
                    e.preventDefault();
                    $parent = $(this).parents('section');
                    // increment this element's data-layout number to find the next block
                    $nextBlock = $parent.data('layout') + 1;
                    //console.log($parent);

                        $("html, body").animate(
                            {
                                scrollTop: $('[data-layout="' + $nextBlock + '"]').offset().top - 65
                            },
                            500
                        );
                 });


                /**
                 *  Mobile sub menu expansion
                 */

                  $('.menus.mobile .menu-item-has-children > a').on('click', function (e) {

                        e.preventDefault();
                        $(this).parent().toggleClass('sub-menu-open');

                        var panel = $(this).next('.sub-menu');

                        if (panel[0].style.maxHeight) {
                          panel[0].style.maxHeight = null;
                        } else {
                          panel[0].style.maxHeight = panel[0].scrollHeight + "px";
                        } 

                  });

                  /**
                   * Make entire multi-block item "clickable"
                   */ 

                   $(".full-clickable-box").on('click', function (e) {

                        $target = $(this).data('target');
                        $href = $(this).data('href');

                        if ($target == "_blank") {
                            window.open($href,'_blank');
                        } else {
                            window.location.href = $href;    
                        }

                        
                   });
            }
        },
        /**
         *  FAQ Block
         */
        faq_block: {
            init: function() {
                /**
                 *  Accordion functionality
                 */

                var acc = document.getElementsByClassName("question");
                var i;

                for (i = 0; i < acc.length; i++) {
                    acc[i].onclick = function(e) {
                        e.preventDefault();

                        this.classList.toggle("active");
                        var panel = this.nextElementSibling;

                        if (panel.style.maxHeight) {
                            panel.style.maxHeight = null;
                        } else {
                            panel.style.maxHeight = panel.scrollHeight + "px";
                        }
                    };
                }
            }
        },
        form_block: {
            init: function() {

                /**
                 *  Floating labels on forms
                 */
                $(".gfield").each(function() {
                    $inputContainer = $(this).find(".ginput_container");

                    if ($inputContainer.hasClass("ginput_container_textarea")) {
                        $input = $(this).find("textarea");
                    } else {
                        $input = $(this).find("input");
                    }

                    $input.on("change keyup input focusin", function() {
                        $(this)
                            .parent()
                            .parent()
                            .addClass("has-input");
                    });

                    $input.on("focusout", function() {
                        if ($(this).val() !== "") {
                            $(this)
                                .parent()
                                .parent()
                                .addClass("has-input");
                        } else {
                            $(this)
                                .parent()
                                .parent()
                                .removeClass("has-input");
                        }
                    });
                });
            }
        },
        slider_block: {
            init: function() {
                /**
                 *  Slick Slider
                 *  Intialize slick slider on info slider block
                 *  and support multiple instances with "each"
                 *  @link http://kenwheeler.github.io/slick/
                 */
                $(".slider").each(function() {
                    $slideID = $(this).find('.slider-container');

                    $slideID.slick({
                        dots: false,
                        infinite: true,
                        autoplay: true,
                        autoplaySpeed: 5000,
                        prevArrow:
                            '<div class="slick-prev"><i class="icon-left-chevron"></i></div>',
                        nextArrow:
                            '<div class="slick-next"><i class="icon-right-chevron"></i></div>',
                    });
                });
            }
        },
        counter_block: {
            init: function() {
               $('.counter-number').counterUp();
            }
        },
        testimonial_slider_block: {
            init: function() {
                /**
                 *  Slick Slider
                 *  Intialize slick slider on info slider block
                 *  and support multiple instances with "each"
                 *  @link http://kenwheeler.github.io/slick/
                 */
                $(".testimonial-slider").each(function() {
                    $slideID = $(this).find('.slider');

                    $slideID.slick({
                        dots: true,
                        infinite: false,
                        autoplay: false,
                        adaptiveHeight: true,
                        prevArrow:
                            '<div class="slick-prev"><i class="icon-double-arrow-left"></i></div>',
                        nextArrow:
                            '<div class="slick-next"><i class="icon-double-arrow"></i></div>',
                    });
                });
            }
        },
        posts_slider_block: {
            init: function() {
                /**
                 *  Slick Slider
                 *  Intialize slick slider on info slider block
                 *  and support multiple instances with "each"
                 *  @link http://kenwheeler.github.io/slick/
                 */
                $(".posts-slider").each(function() {
                    $slideId = $(this).attr("id");

                    $("#" + $slideId + " .slider").slick({
                        dots: true,
                        infinite: false,
                        autoplay: false,
                        prevArrow:
                            '<div class="slick-prev"><i class="icon-back"></i></div>',
                        nextArrow:
                            '<div class="slick-next"><i class="icon-next"></i></div>',
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        responsive: [
                            {
                                breakpoint: 767,
                                settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1
                                }
                            }
                        ]
                    });
                });
            }
        },
        
        map_block: {
            init: function() {

                /*
                *  new_map
                *
                *  This function will render a Google Map onto the selected jQuery element
                *
                *  @type    function
                *  @date    8/11/2013
                *  @since   4.3.0
                *
                *  @param   $el (jQuery element)
                *  @return  n/a
                */

                function new_map( $el ) {
                    
                    // var
                    var $markers = $el.find('.marker');
                    
                    
                    // vars
                    var args = {
                        zoom        : 16,
                        center      : new google.maps.LatLng(0, 0),
                        mapTypeId   : google.maps.MapTypeId.ROADMAP,
                        styles      : [
                                         {
                                            "featureType": "water",
                                            "elementType": "geometry.fill",
                                            "stylers": [
                                                {
                                                    "color": "#c8d7d4"
                                                }
                                            ]
                                        },
                                        {
                                            "featureType": "landscape",
                                            "elementType": "geometry",
                                            "stylers": [
                                                {
                                                    "color": "#f5f5f5"
                                                },
                                                {
                                                    "lightness": 20
                                                }
                                            ]
                                        },
                                        {
                                            "featureType": "road.highway",
                                            "elementType": "geometry.fill",
                                            "stylers": [
                                                {
                                                    "color": "#ffffff"
                                                },
                                                {
                                                    "lightness": 17
                                                }
                                            ]
                                        },
                                        {
                                            "featureType": "road.highway",
                                            "elementType": "geometry.stroke",
                                            "stylers": [
                                                {
                                                    "color": "#ffffff"
                                                },
                                                {
                                                    "lightness": 29
                                                },
                                                {
                                                    "weight": 0.2
                                                }
                                            ]
                                        },
                                        {
                                            "featureType": "road.arterial",
                                            "elementType": "geometry",
                                            "stylers": [
                                                {
                                                    "color": "#ffffff"
                                                },
                                                {
                                                    "lightness": 18
                                                }
                                            ]
                                        },
                                        {
                                            "featureType": "road.local",
                                            "elementType": "geometry",
                                            "stylers": [
                                                {
                                                    "color": "#ffffff"
                                                },
                                                {
                                                    "lightness": 16
                                                }
                                            ]
                                        },
                                        {
                                            "featureType": "poi",
                                            "elementType": "geometry",
                                            "stylers": [
                                                {
                                                    "color": "#f5f5f5"
                                                },
                                                {
                                                    "lightness": 21
                                                }
                                            ]
                                        },
                                        {
                                            "featureType": "poi.park",
                                            "elementType": "geometry",
                                            "stylers": [
                                                {
                                                    "color": "#dedede"
                                                },
                                                {
                                                    "lightness": 21
                                                }
                                            ]
                                        },
                                        {
                                            "elementType": "labels.text.stroke",
                                            "stylers": [
                                                {
                                                    "visibility": "on"
                                                },
                                                {
                                                    "color": "#ffffff"
                                                },
                                                {
                                                    "lightness": 16
                                                }
                                            ]
                                        },
                                        {
                                            "elementType": "labels.text.fill",
                                            "stylers": [
                                                {
                                                    "saturation": 36
                                                },
                                                {
                                                    "color": "#333333"
                                                },
                                                {
                                                    "lightness": 40
                                                }
                                            ]
                                        },
                                        {
                                            "elementType": "labels.icon",
                                            "stylers": [
                                                {
                                                    "visibility": "off"
                                                }
                                            ]
                                        },
                                        {
                                            "featureType": "transit",
                                            "elementType": "geometry",
                                            "stylers": [
                                                {
                                                    "color": "#f2f2f2"
                                                },
                                                {
                                                    "lightness": 19
                                                }
                                            ]
                                        },
                                        {
                                            "featureType": "administrative",
                                            "elementType": "geometry.fill",
                                            "stylers": [
                                                {
                                                    "color": "#fefefe"
                                                },
                                                {
                                                    "lightness": 20
                                                }
                                            ]
                                        },
                                        {
                                            "featureType": "administrative",
                                            "elementType": "geometry.stroke",
                                            "stylers": [
                                                {
                                                    "color": "#fefefe"
                                                },
                                                {
                                                    "lightness": 17
                                                },
                                                {
                                                    "weight": 1.2
                                                }
                                            ]
                                        }
                                    ]
                    };
                    
                    
                    // create map               
                    var map = new google.maps.Map( $el[0], args);
                    
                    
                    // add a markers reference
                    map.markers = [];
                    
                    
                    // add markers
                    $markers.each(function(){
                        
                        add_marker( $(this), map );
                        
                    });
                    
                    
                    // center map
                    center_map( map );
                    
                    
                    // return
                    return map;
                    
                }

                /*
                *  add_marker
                *
                *  This function will add a marker to the selected Google Map
                *
                *  @type    function
                *  @date    8/11/2013
                *  @since   4.3.0
                *
                *  @param   $marker (jQuery element)
                *  @param   map (Google Map object)
                *  @return  n/a
                */

                function add_marker( $marker, map ) {

                    // var
                    var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

                    // create marker
                    var marker = new google.maps.Marker({
                        position    : latlng,
                        map         : map,
                        icon        : $marker.data('icon')
                    });

                    // add to array
                    map.markers.push( marker );

                    // if marker contains HTML, add it to an infoWindow
                    if( $marker.html() )
                    {
                        // create info window
                        var infowindow = new google.maps.InfoWindow({
                            content     : $marker.html()
                        });

                        // show info window when marker is clicked
                        google.maps.event.addListener(marker, 'click', function() {

                            infowindow.open( map, marker );

                        });
                    }

                }

                /*
                *  center_map
                *
                *  This function will center the map, showing all markers attached to this map
                *
                *  @type    function
                *  @date    8/11/2013
                *  @since   4.3.0
                *
                *  @param   map (Google Map object)
                *  @return  n/a
                */

                function center_map( map ) {

                    // vars
                    var bounds = new google.maps.LatLngBounds();

                    // loop through all markers and create bounds
                    $.each( map.markers, function( i, marker ){

                        var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

                        bounds.extend( latlng );

                    });

                    // only 1 marker?
                    if( map.markers.length == 1 )
                    {
                        // set center of map
                        map.setCenter( bounds.getCenter() );
                        map.setZoom( 16 );
                    }
                    else
                    {
                        // fit to bounds
                        map.fitBounds( bounds );
                    }

                }

                /*
                *  document ready
                *
                *  This function will render each map when the document is ready (page has loaded)
                *
                *  @type    function
                *  @date    8/11/2013
                *  @since   5.0.0
                *
                *  @param   n/a
                *  @return  n/a
                */
                // global var
                var map = null;

                $(document).ready(function(){

                    $('.acf-map').each(function(){

                        // create map
                        map = new_map( $(this) );

                    });

                });

            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = funcname === undefined ? "init" : funcname;
            fire = func !== "";
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === "function";

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire("common");

            // Fire page-specific init JS, and then finalize JS
            $.each(
                document.body.className.replace(/-/g, "_").split(/\s+/),
                function(i, classnm) {
                    UTIL.fire(classnm);
                    UTIL.fire(classnm, "finalize");
                }
            );

            // Fire common finalize JS
            UTIL.fire("common", "finalize");
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
